import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "meeting-planning-sub.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 50, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 1000},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="Meeting Planning | ECI Services" />
      <BgImage image={pluginImage} className="subhead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>Meeting Planning</h1>
              
            </Container>
          </div>
        </div>
      </BgImage>
      <Container className="singleCol">
        <h2 className="orangeBarBelow mb-4">Let us partner with you to maximize your return on your meeting investment.</h2>
        <p>You need a meeting with marked results. Our professionalism, dedication and experience are key to helping make that happen.</p>

        <p>ECI is focused and nimble and we quietly become an extension of your executive management team. Your priorities are our priorities. Your goals become our goals. We manage your meetings like we’d manage our own making sure you can maximize and measure your return on your meeting investment.</p>

        <p>We wrangle the details of rate negotiation, costs containment and other minutia and deliver them to you in a cohesive presentation leaving you time to focus on your content. Let us partner with you to take your vision of a successful meeting from paper to accomplishment.</p>
        <p>
        <StaticImage
              src="../../images/meeting-planning.jpg"
              width={750}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
              className="rounded"
            />
        </p>
     </Container>
    </Layout>
          
  )
}

export default IndexPage2